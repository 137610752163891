<mat-card>
  <h4 class="w3-center">My Dictionaries</h4>

  <!-- Top Bar-->
  <table>
    <tr>
      <td style="width: 100%;">
       <mat-form-field class="w3-col">
         <input [(ngModel)]="vocab" matInput placeholder="Search a word" >        
       </mat-form-field>
      </td>
      <td>
        <button (click)="new_vocab_word = vocab" matTooltipPosition="above" matTooltip="Add to vocab list" class="w3-col" mat-icon-button>
          <mat-icon  >add</mat-icon>
        </button>
       </td>
      <td>
       <button matTooltipPosition="above" matTooltip="By Merriam-Webster Learner's Dictionary" class="w3-col" mat-icon-button>
         <mat-icon  >search</mat-icon>
       </button>
      </td>
      <td>
       <button matTooltipPosition="above" matTooltip="Listen" class="w3-col" mat-icon-button>
         <mat-icon (click)="speak(vocab)" >volume_up</mat-icon>
       </button>
      </td>
     
    </tr>
  </table>

  <!-- -->
  <table class="w3-table" width="100%" style="text-align: center;">
    <span *ngFor="let dictionary of dictionaries |keyvalue" >
      <tr><td style="min-width: 200px;"><h6 align="left"><i class="fa fa-search"></i> {{dictionary.key}}</h6></td></tr>

      <tr>
        <td>
          <span *ngFor="let icon of dictionary.value">
            <a *ngIf="!icon.list" target="_blank" (click)="openURL(icon)">
              <img  src="{{icon.image}}" style="height:35px; margin-right: 2px;cursor: pointer;" >            
            </a>

            <a *ngIf="icon.list" target="_blank" [matMenuTriggerFor]="menu">
              <img  src="{{icon.image}}" style="height:35px; margin-right: 2px;" >            
            </a>
            <mat-menu #menu="matMenu">
              <button *ngFor="let item of icon.list" (click)="openURL(item)" mat-menu-item>{{item.name}}</button>

            </mat-menu>
          </span>
          
        </td>     
      </tr>
    </span>
   
    
    </table>  

</mat-card>