import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-ers',
  templateUrl: './ers.component.html',
  styleUrls: ['./ers.component.css']
})
export class ErsComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
  ) { }
  minPage = 0
  maxPage = 0
  books
  ngOnInit(): void {

    
  }

  new_book_form = this.fb.group({
    title: ['',Validators.required],
    author: ['',Validators.required],
    page: ['',Validators.required],
    genre: ['',Validators.required],
    source: ['',Validators.required],
  
  });
  
  progress_form = this.fb.group({  
    bookid: ['',Validators.required],
    minute: ['',Validators.required],
    page: ['',Validators.required], 

  });

  sources
  genres
  updateProgress(){
    

  }
  changeMaxMinPageNo(){

  }
  inputBook(){

  }
}
