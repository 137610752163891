import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {MatSnackBar , MatSnackBarRef} from '@angular/material/snack-bar';

import { EeService } from './services/ee.service';
import { formatCurrency } from '@angular/common';
import { throwError } from 'rxjs';
import { voice} from 'responsiveVoice';
import { AngularFireAuth } from '@angular/fire/compat/auth';

declare let responsiveVoice: any; //declare moment

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ee2';
  user
  loggedIn: boolean;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = ELEMENT_DATA;
  role = 0;
  books
  new_book_form;
  progress_form

  snackBarRef: any;
  // Static Data
  sources
  genres


  //Temp 
  minPage = 0
  maxPage = 0

  // Dictionary
  vocab
  dictionary_result
  oxford_dictionary_result
  rhyme_dictionary_result
  vocabularycom_dictionary_result
  jtw_dictionary_result
  onelook_dictionary_result
  dictionary_index = 0

  // Vocab List
  vocab_list
  vocab_list_words
  selected_vocab_list
  showshare = false;
  
  constructor(
  
    private eeService: EeService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public auth: AngularFireAuth,
   ) { }

    getVocabListWords(list){
      this.selected_vocab_list = list;
      this.eeService.getVocab(list.id).subscribe(data => {
        this.vocab_list_words = data
      
      })
    }
   
    speak(word){
      responsiveVoice.speak(word);
    }

    getLearnerDictionary(word){
      this.dictionary_result = null;
      this.eeService.getLearnerDictionary(word).subscribe((res) => {
        this.dictionary_result = res
        console.log(res)
       
        console.log(this.dictionary_index )
        this.dictionary_index = 1
      })

  //    this.getOxfordDictionary(word)
        this.getJustTheWordDictionary(word);
        this.getRhymeDictionary(word);
        this.getOneLookDictionary(word);
        this.getVocabularycomDictionary(word);
    }
/*

    
    getOxfordDictionary(word){
      this.eeService.getOxfordDictionary(word).subscribe((res) => {
        this.oxford_dictionary_result = res
        console.log(res)
       
        console.log(this.dictionary_index )
        this.dictionary_index = 1
      })
    }
*/


    getVocabularycomDictionary(word){
      this.vocabularycom_dictionary_result = null;
      this.eeService.getVocabularycomDictionary(word).subscribe((res) => {
        this.vocabularycom_dictionary_result = res
        console.log(res)
      
        console.log(this.dictionary_index )
        this.dictionary_index = 1
      })
      
    }

    getRhymeDictionary(word){
      this.rhyme_dictionary_result = null;
      this.eeService.getRhymeDictionary(word).subscribe((res) => {
        this.rhyme_dictionary_result = res
        console.log(res)
       
        console.log(this.dictionary_index )
        this.dictionary_index = 1
      })
      
    }

    getOneLookDictionary(word){
      this.onelook_dictionary_result = null;
      this.eeService.getOneLookDictionary(word).subscribe((res) => {
        this.onelook_dictionary_result = res
        console.log(res)
       
       
        this.dictionary_index = 1
      })
      
    }


    getJustTheWordDictionary(word){
      this.jtw_dictionary_result = null;
      this.eeService.getJustTheWordDictionary(word).subscribe((res) => {
        this.jtw_dictionary_result = res
        console.log(res)
       
        console.log(this.dictionary_index )
        this.dictionary_index = 1
      })
      
    }
    onActivate(event) {
      let scrollToTop = window.setInterval(() => {
          let pos = window.pageYOffset;
          if (pos > 0) {
              window.scrollTo(0, pos - 20); // how far to scroll on each step
          } else {
              window.clearInterval(scrollToTop);
          }
      }, 16);
  }

   ngOnInit() {

    this.auth.user.subscribe(data => {
        console.log(data)
        this.user = data
      })

    this.new_book_form = this.fb.group({
     
      title: ['',Validators.required],
      author: ['',Validators.required],
      page: ['',Validators.required],
      genre: ['',Validators.required],
      source: ['',Validators.required],
    
    });
    
    this.progress_form = this.fb.group({
    
      bookid: ['',Validators.required],
      minute: ['',Validators.required],
      page: ['',Validators.required],
     

    });
 /*
    this.authService.authState.subscribe((user) => {
      if (user){
        this.eeService.serverLogin(user.idToken).subscribe(data => {
          this.eeService.getVocabLists().subscribe(data => {
            this.vocab_list = data
            console.log(data)
          })
     
          if (data["hd"] == 'plkcfs.edu.hk'){        
            this.role = 2
          } else{
            this.role = 1
          }  

          // Get Books Data
          this.getBooks();

          this.getStaticData();
        })
      } 
      this.user = user;
      this.loggedIn = (user != null);
      console.log(user);
     
    }); */
  }
  getBooks(){
    this.eeService.getBooks().subscribe(books => {
      this.books = books
      console.log(books)
    })
  }
  getStaticData(){
    this.eeService.getSources().subscribe(sources => {
      this.sources = sources

    })
    this.eeService.getGenres().subscribe(genres => {
      this.genres = genres
    })
  }

  signIn(): void {
   // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
 
 
  signOut(): void {
   // this.eeService.serverLogout().subscribe(data => {
   //   this.authService.signOut();
   //   this.role = 0;
 //   })
    
  }

  updateProgress(){
    console.log(this.progress_form.value);
    this.eeService.updateProgress(this.progress_form.value).subscribe(data => {
      this._snackBar.open("Done!", "OK", {
        duration: 2000,
      });
      this.progress_form.reset();
      this.getBooks();
    })

  }

  new_vocab_word
  addVocab(){
    let data = {word: this.new_vocab_word , list: this.selected_vocab_list.id};

    this.eeService.addVocab(data).subscribe(res => {
      this.getVocabListWords(this.selected_vocab_list);
      this.new_vocab_word = ''
      this._snackBar.open("Added!", "OK", {
        duration: 2000,
      });

    })

  }

  deleteVocab(word){
    let data = {word: word , list: this.selected_vocab_list.id};

    this.eeService.deleteVocab(data).subscribe(res => {
      this.getVocabListWords(this.selected_vocab_list);
     
      this._snackBar.open("Deleted!", "OK", {
        duration: 2000,
      });

    })

  }

  new_list_name
  addList(){
    let data = {name: this.new_list_name };

    this.eeService.addList(data).subscribe(data => {
      this.eeService.getVocabLists().subscribe(data => {
        this.vocab_list = data
        this.new_list_name = ''
        this._snackBar.open("Done!", "OK", {
          duration: 2000,
        });
      })
  
    })
  }

  undo_list;
  deleteList(list){
    let data = {id: list.id };
    this.undo_list = list;
    this.eeService.deleteList(data).subscribe(data => {
      this.eeService.getVocabLists().subscribe(data => {
        this.vocab_list = data
      
        this._snackBar.open("Deleted!", "Undo", {
          duration: 10000,
        }).onAction().subscribe(() => {
          this.undodeleteList(this.undo_list)
        });
      })
  
    })
  }
  undodeleteList(list){
  
    this.eeService.addList(list ,1).subscribe(data => {
      this.eeService.getVocabLists().subscribe(data => {
        this.vocab_list = data
       
        let snackBarRef = this._snackBar.open("Undo Successfully!", "OK", {
          duration: 2000,
        })
      })
  
    })
  }


  inputBook(){
  console.log(this.new_book_form.value);
      this.eeService.inputBook(this.new_book_form.value).subscribe(data => {
        this._snackBar.open("Done!", "OK", {
          duration: 2000,
        });
        this.new_book_form.reset();
        this.getBooks();
      })
  }

  changeMaxMinPageNo(){
    console.log(this.books)
    const temp_book = this.books.find((b) =>  
      
      b.bookid == this.progress_form.value.bookid
   )
    
    console.log(temp_book)
    this.minPage = temp_book.sumpage;
    this.maxPage = temp_book.page;
  }
}
