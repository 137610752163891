<mat-card>
    <h4 class="w3-center">My ERS</h4>        

    <mat-tab-group>
        <mat-tab label="Add"> 
         
          <div class="w3-container">
            <form [formGroup]="new_book_form">
              <div class="w3-row" >                
                <div class="w3-col ">
                  <mat-form-field style="width: 100%;">
                    <mat-label>Book Name</mat-label>
                    <input formControlName="title" matInput>
                  </mat-form-field>
                </div>
              </div>
              <div class="w3-row" >                
                <div class="w3-col ">
                  <mat-form-field style="width: 100%;">
                    <mat-label>Author</mat-label>
                    <input formControlName="author" matInput>
                  </mat-form-field>
                </div>
              </div>
              <div class="w3-row" >                
                <div class="w3-col w3-half">
                  <mat-form-field style="width: 100%;">
                    <mat-label>Genre</mat-label>                   
                    <mat-select formControlName="genre" >
                      <mat-option *ngFor="let genre of genres" [value]="genre.name" >{{genre.name}}</mat-option>                       
                    </mat-select>                     
                  </mat-form-field> 
                </div>                           
                <div class="w3-col w3-half ">
                  <mat-form-field style="width: 100%;">
                    <mat-label >No. of Pages</mat-label>
                    <input formControlName="page" matInput type="number" min="0">
                  </mat-form-field>
                </div>
              </div>
              <div class="w3-row" >                
                <div class="w3-col ">
                  <mat-form-field style="width: 100%;">
                    <mat-label>Source</mat-label>                   
                    <mat-select formControlName="source">
                      <mat-option *ngFor="let source of sources" [value]="source.name" >{{source.name}}</mat-option>               
                    </mat-select>                     
                  </mat-form-field> 
                </div>
              </div>
              <div class="w3-row">
                <div class="w3-col">
                  <button (click)="inputBook()" mat-button> Submit</button>
                </div>
              </div>
            </form>
          </div>
        </mat-tab>
        <mat-tab label="Read">
          <div class="w3-container">
            <form [formGroup]="progress_form">
             
              <div class="w3-row" >                
                <div class="w3-col ">
                  <mat-form-field style="width: 100%;">
                    <mat-label>Book Name</mat-label>                   
                    <mat-select formControlName="bookid" (selectionChange)="changeMaxMinPageNo()">
                      <mat-option> --- </mat-option>
                      <mat-option *ngFor="let book of books" [value]="book.bookid" >{{book.title}}</mat-option>                       
                    </mat-select>                     
                  </mat-form-field> 
                </div>
              </div>
              <div class="w3-row ">
                <div class="w3-col w3-half">
                  <label><i class="fa fa-clock-o"></i> Reading Time (min)</label>
                </div>
                <div class="w3-col w3-half">
                  <mat-slider thumbLabel formControlName="minute" style="width: 100%;"></mat-slider>
                </div>
              </div>
              <div class="w3-row ">
                <div class="w3-col w3-half">
                  <label><i class="fa fa-clock-o"></i> Page No. Now</label>
                </div>
                <div class="w3-col w3-half">
                  <mat-slider [max]="maxPage"  [min]="minPage" thumbLabel formControlName="page" style="width: 100%;"></mat-slider>
                </div>
              </div>
              <div class="w3-row">
                <div class="w3-col">
                  <button (click)="updateProgress()" mat-button> Submit</button>
                </div>
              </div>
            </form>
          </div>
        
        </mat-tab>
       
      </mat-tab-group>
</mat-card>