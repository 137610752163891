import { Component, OnInit, ReflectiveKey } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { EeService } from '../services/ee.service';
declare let responsiveVoice: any; //declare moment

@Component({
  selector: 'app-vocablist',
  templateUrl: './vocablist.component.html',
  styleUrls: ['./vocablist.component.css']
})


export class VocablistComponent implements OnInit {

  rootRef : firebase.default.database.Reference
  constructor(
    public auth: AngularFireAuth, 
    private db: AngularFireDatabase,
    private eeService:EeService) {
    this.rootRef = this.db.database.ref();
  }

  private vocabListPath = '/vocablist/lists';
  
  getMyVocabLists(){
    return this.eeService.getMyVocabLists().then(ret => {
      let data = Object.entries(ret).map(record => {                
        return {id: record[0], ...Object(record[1])}
      })
      this.vocab_list[0] = data   
    })
  }

  getPublicVocabLists(){
    return this.eeService.getPublicVocabLists().then(ret => {
      let data = Object.entries(ret).map(record => {                
        return {id: record[0], ...Object(record[1])}
      })
      this.vocab_list[1] = data   
    })
  }

  getVocabListByID(id){
    return this.eeService.getVocabListByID(id).then(ret => {
      console.log({id: id, ...Object(ret)})
      console.log(this.selected_vocab_list)
      this.selected_vocab_list = {id: id, ...Object(ret)}
      this.vocab_list_words = this.selected_vocab_list.words
    })
  }

  addMyVocabList(){
    let data = {
      'name': this.new_list_name,
      'words': ['Enter Some Word Above!','Enter Some Word Above!']
    }

    return this.eeService.addMyVocabList(data).then(ret => {
      this.getMyVocabLists()
    })
  }

  deleteMyVocabList(vocablistID){

   return this.eeService.deleteMyVocabList(vocablistID).then(ret => {
     this.getMyVocabLists()
   })
  }

  viewVocabList(vocablist){
    this.selected_vocab_list = vocablist
    this.vocab_list_words = vocablist.words
  }

  addVocabWord(){
    this.eeService.addVocabWord(this.selected_vocab_list,this.new_vocab_word).then(ret => {
      this.getVocabListByID(this.selected_vocab_list.id)
    
    })
  }

  deleteVocabWord(word){
    console.log(this.vocab_list_words)
    console.log(word)
    this.vocab_list_words = this.vocab_list_words.filter(data => {
      return data != word
    })
    this.eeService.deleteVocabWord(this.selected_vocab_list,this.vocab_list_words).then(ret => {
      this.getVocabListByID(this.selected_vocab_list.id)
    
    })

  }

  changeShare(){

    let share_list = this.selected_vocab_list.share_view.toUpperCase().split(',')

    let keys = Object.keys(this.selected_vocab_list)
   
    keys.forEach(key => {
      if (key.substring(0,6) == "share_" && key.substring(0,10) != "share_view"){
        delete this.selected_vocab_list[key] 
      }
    })
    
    share_list.forEach(obj => {
      this.selected_vocab_list["share_"+obj] = 1
    })

    console.log( this.selected_vocab_list)
    this.eeService.changeShare(this.selected_vocab_list).then(ret => {
      this.getVocabListByID(this.selected_vocab_list.id)
    
    })

   
  }

  user = {
    email : "",
  }

  getEmailStatus(){
    if (this.user.email.split("@")[1] === "plkcfs.edu.hk" || this.user.email === "bbricky2003@gmail.com"){
      return 1
    }
    else{
      return 0
    }
  }
  vocab_list = []
  selected_vocab_list
  

  ngOnInit(): void {

    this.selected_vocab_list = null

    this.vocab_list = [
      [],
      [],
      [],
      []
    ]
  
    this.auth.user.subscribe(data => {

      this.user = data      

      this.getMyVocabLists()
      this.getPublicVocabLists()
    })

    

    
  }

  vocab
  vocab_list_words = [

    
  ]
  new_vocab_word

  showshare

  new_list_name

  speak(word){
    responsiveVoice.speak(word);
  }
  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 16);
 }
 deleteVocab(word){
 }
 deleteList(list){
   
 }
 addVocab(){

 }

 getVocabListWords(list){

 }
 addList(){

 }
}
