import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

declare let responsiveVoice: any; //declare moment


@Component({
  selector: 'app-dictionary',
  templateUrl: './dictionary.component.html',
  styleUrls: ['./dictionary.component.css']
})
export class DictionaryComponent {
  analytics: AngularFireAnalytics
  constructor(analytics: AngularFireAnalytics) {
    this.analytics = analytics
   }
  vocab
  new_vocab_word
  


  dictionaries = {
    "1. Dictionaries" : [
      {image: 'assets/images/m-w.gif' ,  URL: 'http://www.learnersdictionary.com/definition/<vocab>' },
      {image: 'assets/images/oxford.png' ,  URL: 'http://www.oxfordlearnersdictionaries.com/definition/english/<vocab>', },
      {image: 'assets/images/cambridge.png' ,  URL: 'http://dictionary.cambridge.org/dictionary/learner-english/<vocab>', },
      {image: 'https://is3-ssl.mzstatic.com/image/thumb/Purple4/v4/d3/a5/c0/d3a5c0c8-4615-a4bc-8ae6-7837dc254beb/mzl.uamfefwy.png/246x0w.jpg' ,  URL: 'https://www.vocabulary.com/dictionary/<vocab>', }
    ],
    "2. Thesauruses" : [
      {image: 'assets/images/macmillan.png' ,  URL: 'https://www.macmillanthesaurus.com/<vocab>' , comma:true },
      {image: 'assets/images/thesaurus.com.jpg' ,  URL: 'http://www.thesaurus.com/browse/<vocab>', },
      {image: 'assets/images/cambridge.png' ,  URL: 'https://www.merriam-webster.com/thesaurus/<vocab>', },
      {image: 'https://1.bp.blogspot.com/-RLkdBVOxQ4I/WbO6UkQHW4I/AAAAAAAAAR8/kj0HqmNsl5AvUgVJxVNmbx1-nnbetycbQCLcBGAs/s1600/images.jpg' ,  URL: 'https://www.onelook.com/reverse-dictionary.shtml?s=<vocab>', }
    ],
    "3. Collocations" : [
      {image: 'assets/images/oz.png' ,  URL: 'https://ozdic.com/collocation/<vocab>' },
      {image: 'assets/images/jtw.png' ,  URL: 'http://www.just-the-word.com/main.pl?word=<vocab>', },
      {image: 'assets/images/oxford.png' ,  URL: 'http://www.freecollocation.com/search?word=<vocab>', },
    ],
    "4. Sentences" : [
      {image: 'assets/images/skell2.png' ,  URL: 'https://skell.sketchengine.eu/#result?lpos=&query=<vocab>&lang=en&f=concordance' },
      {image: 'assets/images/fraze.png' ,  URL: 'https://fraze.it/n_search.jsp?q=<vocab>', },
      {image: 'assets/images/sentencedict.gif' ,  URL: 'http://sentencedict.com/<vocab>.html', },
      {image: 'https://youglish.com/images/brandyg.png' ,  URL: 'https://youglish.com/pronounce/<vocab>/english?', },
    ],
    "5. Literary Devices" : [
      {image: 'assets/images/rz.png' ,  URL: 'http://www.rhymezone.com/r/rhyme.cgi?Word=<vocab>&typeofrhyme=adv&org1=syl&org2=l&org3=y' },
      {image: 'assets/images/idioms.png' ,  URL: 'https://idioms.thefreedictionary.com/<vocab>', },
      {image: 'assets/images/meta.jpeg' ,  URL: 'http://www.metamia.com/search-analogy.php?s=0&q=<vocab>', }, 
    ],
    "6. Word Family" : [
      {image: 'https://lh3.googleusercontent.com/K39nj5ZgRe42gjHZrJgEpR4fcHVk89pe5qGK8bPu-Ql-PK6Qkb3p_7c-aYolxhRMgw' ,  URL: 'http://www.rhymezone.com/r/rhyme.cgi?Word=<vocab>&typeofrhyme=adv&org1=syl&org2=l&org3=y', 
        list:[
          {URL:'https://www.wordhippo.com/what-is/the-plural-of/<vocab>.html', name:'Plural of'},
          {URL:'https://www.wordhippo.com/what-is/the-singular-of/<vocab>.html', name:'Singular of'},
          {URL:'https://www.wordhippo.com/what-is/the-past-tense-of/<vocab>.html', name:'Past tense of'},
          {URL:'https://www.wordhippo.com/what-is/the-present-tense-of/<vocab>.html', name:'Present tense of'},
          {URL:'https://www.wordhippo.com/what-is/the-verb-for/<vocab>.html', name:'Verb for '},
          {URL:'https://www.wordhippo.com/what-is/the-adjective-for/<vocab>.html', name:'Adjective for'},
          {URL:'https://www.wordhippo.com/what-is/the-adverb-for/<vocab>.html', name:'Adverb for'},
          {URL:'https://www.wordhippo.com/what-is/the-noun-for/<vocab>.html', name:'Noun for'},
          {URL:'https://www.wordhippo.com/what-is/another-word-for/<vocab>.html', name:'Another word for'},
          {URL:'https://www.wordhippo.com/what-is/the-opposite-of/<vocab>.html', name:'Opposite of'},
          {URL:'https://www.wordhippo.com/what-is/the-meaning-of-the-word/<vocab>.html', name:'Meaning of'},
          {URL:'https://www.wordhippo.com/what-is/words-that-rhyme-with/<vocab>.html', name:'Rhymes with'},
          {URL:'https://www.wordhippo.com/what-is/sentences-with-the-word/<vocab>.html', name:'Sentences with'},
          {URL:'https://www.wordhippo.com/what-is/words-starting-with/<vocab>.html', name:'Words starting with'},
          {URL:'https://www.wordhippo.com/what-is/words-ending-with/<vocab>.html', name:'Words ending with'},
          {URL:'https://www.wordhippo.com/what-is/words-containing-the-letters/<vocab>.html', name:'Words containing exactly'},
          {URL:'https://www.wordhippo.com/what-is/how-do-you-pronounce-the-word/<vocab>.html', name:'Pronounce'},
          {URL:'https://www.wordhippo.com/what-is/search-page/<vocab>.html', name:'Find conjugations'},
        ] },
      {image: 'http://logok.org/wp-content/uploads/2014/09/British-Council-logo-880x660.png' ,  URL: 'https://494f9c0dda672f79b2ee-6b9e395a7343d6a0b8b7ac609388ce35.ssl.cf1.rackcdn.com/wff/index.html?_ga=2.249375303.1124166549.1539595989-2034075328.1536743758', },
      {image: 'https://www.etymonline.com/graphics/header.jpg' ,  URL: 'https://www.etymonline.com/search?q=<vocab>', }, 
    ],
    
  }
  openURL(dictionary){
    console.log(this.vocab)
    console.log(dictionary)
    console.log(dictionary.URL)
    this.vocab = this.vocab.replace(/\s+/g, ' ').trim()
    console.log(this.vocab)
    let url = dictionary.URL.replaceAll("<vocab>", this.vocab)

    if(dictionary?.comma){
      url = url.replace(/\s+/g, ' ').trim().replaceAll(" ", '-')
    }

   
    window.open(url, '_blank').focus();
    this.analytics.logEvent('dictionary', {vocab: this.vocab, url: url})

  }
  speak(word){
    responsiveVoice.speak(word);
    
  }

}
