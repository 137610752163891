<div class="w3-container w3-content" style="max-width:1400px;margin-top:60px">    
  <!-- The Grid -->
  <div class="w3-row" style="margin-bottom: 10px;">
    <app-user></app-user>
  </div>
 
  <div *ngIf="!user">
    <mat-card style="height: 100%;">
     
      <img style="max-width: 100%;" src="https://powerlanguage.net/wp-content/uploads/2019/09/welcome.jpg">
    
    </mat-card>
  </div>

  <!-- After Login-->
  <div *ngIf="user">
    <div class="w3-row" style="margin-bottom: 10px;">
      <app-dictionary></app-dictionary>
    </div>
    
    <mat-tab-group>
      <mat-tab disabled label="My ERS">
        <div class="w3-row" style="margin-bottom: 10px;">
          <div class="w3-col l4">
            <app-ers></app-ers>
          </div>
        </div>
      </mat-tab>
      <mat-tab  label="My Vocab"> 
        <app-vocablist></app-vocablist>
      </mat-tab>
    </mat-tab-group>
  </div>


<!-- End Page Container -->
</div>