<mat-card>
  <div class="w3-row"  *ngIf="auth.user | async as user; else showLogin">
    <div class="w3-container ">
      <div class="w3-col s4">
          <img id="user_pic" src="{{user.photoURL}}" class="w3-circle w3-margin-right" style="width:46px">
        </div>
        <div class="w3-col s8 w3-bar">
          <span >Welcome, <strong id="user_name">{{ user.displayName }}</strong></span><br>
          <span style="margin-right: 10px;">{{user.email}}</span>
          <button mat-button class="w3-pale-red" (click)="logout()">Logout</button>
        </div>
      </div>
   
     
  </div>
    <ng-template #showLogin>
      <div class="w3-container w3-row" >
        <div class="w3-col s4">
            <img id="user_pic" src="https://www.w3schools.com/w3images/avatar3.png" class="w3-circle w3-margin-right" style="width:46px">
          </div>
          <div class="w3-col s8 w3-bar">
            <span >Welcome, <strong id="user_name">Guest</strong></span><br>
            
            
          </div>
       
        <button mat-button class="w3-green" (click)="login()">Login</button>
    </div>
     
    </ng-template>
</mat-card>

   