<mat-drawer-container class="w3-theme-l5"  style="min-height: 1000px;"  autosize>
    <mat-drawer class="w3-container"  [opened]="true"  #drawer  mode="side">
      <h5>My Vocab List</h5>
      <hr>
      <table>
        <tr  *ngFor="let list of vocab_list[0] "  style="width: 100%">
          <td>
            <button (click)="deleteMyVocabList(list.id)" mat-icon-button><mat-icon>clear</mat-icon> </button>
          </td>
          <td  colspan="3" style="vertical-align:bottom; width: 100%;">
            <button [class.w3-theme-l2]="list.id == selected_vocab_list?.id" (click)="viewVocabList(list);drawer.toggle(); showshare = false" mat-button class="w3-col" style="text-align: left;">{{list.name}}</button>
          </td>
          
        </tr>
      </table>

      <table>
        <tr style="width: 100%">
          <td colspan="3" style="vertical-align:bottom; width: 100%;">
            <input [(ngModel)]="new_list_name"  matInput placeholder="Add a List" >
          </td>
          <td>
            <button (click)="addMyVocabList()" mat-icon-button><mat-icon>add</mat-icon> </button>
          </td>
        </tr>
        </table>
      <h5>Shared with me</h5>
      
      <!-- Public list-->
      <h6>Public</h6>
      <button *ngFor="let list of vocab_list[1]" [class.w3-theme-l2]="list.id == selected_vocab_list?.id" (click)="viewVocabList(list);drawer.toggle()" mat-button class="w3-col" style="text-align: left;"><i class="fa fa-pencil"></i> {{list.name}}</button>
      
      
      <!-- Form List-->
      <span *ngIf="getEmailStatus() == 1">
        <span *ngFor="let form of [1,2,3,4,5,6]">
          <h6>Form {{form}} </h6>
          <button *ngFor="let list of vocab_list[2]" [class.w3-theme-l2]="list.id == selected_vocab_list?.id" (click)="getVocabListWords(list);drawer.toggle()" mat-button class="w3-col" style="text-align: left;"><i class="fa fa-pencil"></i> {{list.name}}</button>
      
        </span>
      </span>
      <span *ngIf="getEmailStatus() == 0">
        <h6>Form</h6>
      <button *ngFor="let list of vocab_list[2]" [class.w3-theme-l2]="list.id == selected_vocab_list?.id" (click)="getVocabListWords(list);drawer.toggle()" mat-button class="w3-col" style="text-align: left;"><i class="fa fa-pencil"></i> {{list.name}}</button>
      
      </span>
      
      
      <!-- Class List-->
      <h6>Class</h6>
      <button *ngFor="let list of vocab_list[3]" [class.w3-theme-l2]="list.id == selected_vocab_list?.id" (click)="getVocabListWords(list);drawer.toggle()" mat-button class="w3-col" style="text-align: left;"><i class="fa fa-pencil"></i> {{list.name}}</button>
      
    </mat-drawer>
  
    <div>
      
     <table class="w3-theme-l3"  style="width: 100%;">
       <tr style="width: 100%;">
         <td style="width: 100%;">
          <button type="button" class="w3-theme-l3" mat-button (click)="drawer.toggle()">
            <mat-icon>more_vert</mat-icon> Menu
          </button>
         </td>
       
         <td>
          <span  *ngIf="selected_vocab_list">
            <button mat-button class="w3-theme-l3" [matMenuTriggerFor]="option_menu" *ngIf="selected_vocab_list.email == user.email" >
              <mat-icon>more_vert</mat-icon> Option
            </button>
          </span>
         
          <mat-menu #option_menu="matMenu">
            <button (click)="showshare = !showshare" mat-menu-item>
              <mat-icon>folder_shared</mat-icon>
              <span>Share</span>
            </button>
           
          </mat-menu>
         </td>
       </tr>
     </table>
     <div class="w3-container" *ngIf="!selected_vocab_list">
       <hr>
       <- Please Select / Create a Vocab List
     </div>
     <div *ngIf="selected_vocab_list ">
     
        <h5 style="text-align: center;"> {{selected_vocab_list?.name}}</h5>
        <h6 style="text-align: center;"> by {{selected_vocab_list?.user_display_name}}  </h6>
        <h6 style="text-align: center;">  No. of words : {{vocab_list_words?.length}}</h6>
       
     </div>
      <hr>
      <div *ngIf="selected_vocab_list " class="w3-container">
        <mat-card *ngIf="showshare">
          <table class="w3-table">
            <tr>
              <td colspan="2">
                Share with:
              </td>
              
            </tr>
            <tr>
              <td >  
                <mat-form-field style="width: 100%;" >
                <mat-label>Enter the group here</mat-label>
                <span *ngIf="selected_vocab_list.share_view" matPrefix>  <span *ngFor="let tag of selected_vocab_list.share_view.split(',')" style=" text-transform: uppercase;margin-right: 2px;font-size:12px"  class="w3-tag w3-theme-l4 w3-round-large">{{tag}}</span> 
                &nbsp;</span>
                <input [(ngModel)]="selected_vocab_list.share_view" matInput placeholder="Add a word" >
              </mat-form-field>
              </td>
              <td>
                <button (click)="changeShare()" mat-icon-button><mat-icon>done</mat-icon> </button>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                Rules: <br>
                Share with whole class:  <span  style=" margin-right: 2px;font-size:12px"  class="w3-tag w3-theme-l4 w3-round-large">1A</span> <br>
                Share with whole form:  <span  style=" margin-right: 2px;font-size:12px"  class="w3-tag w3-theme-l4 w3-round-large">F1</span> <br>
                Share with everyone: <span  style=" margin-right: 2px;font-size:12px"  class="w3-tag w3-theme-l4 w3-round-large">All</span> <br>
                Separate each group with comma <hr>
                
                Member Permission:<br>
                + add new word

              </td>
            </tr>
          </table>
        </mat-card>
       
      </div>
    
      
     
      <table *ngIf="selected_vocab_list " class="w3-table w3-striped w3-bordered">
        <tr style="width: 100%">
          <td colspan="3" style="vertical-align:bottom; width: 100%;">
            <input [(ngModel)]="new_vocab_word"  matInput placeholder="Add a word" >
          </td>
          <td>
            <button (click)="addVocabWord()" mat-icon-button><mat-icon>done</mat-icon> </button>
          </td>
        </tr>
        <tr *ngFor="let word of vocab_list_words">
          <td>
            <button (click)="deleteVocabWord(word)" mat-icon-button><mat-icon>clear</mat-icon> </button>
          </td>
          <td style="width: 100%;vertical-align:middle">
            {{word}}
          </td>
          <td>
            <button (click)="vocab = word ; onActivate($event)" mat-icon-button><mat-icon>search</mat-icon> </button>
          </td>
          <td>
            <button mat-icon-button (click)="speak(word)"><mat-icon>volume_up</mat-icon> </button>
          </td>
          
        </tr>
      </table>
    </div>
  
  </mat-drawer-container>