import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { baseURL } from '../shared/baseurl';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    
  })
};
const OxfordhttpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    "app_id": "7d39d2d4",
    "app_key": "e9ae39611e46df6b8b867144895316d9"
  })
};

const PlainTexthttpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
   
  })
};

@Injectable({
  providedIn: 'root'
})
export class EeService {

  rootRef : firebase.default.database.Reference

  constructor(
    private http: HttpClient,
    public auth: AngularFireAuth,
     private db: AngularFireDatabase) {
    this.rootRef = this.db.database.ref();
    this.auth.user.subscribe(data => {

      this.user = data   
 

      this.getMyVocabLists()

    })
  }

  private vocabListPath = '/vocablist/lists';

  user

  getMyVocabLists(){
    return this.rootRef.child(this.vocabListPath).orderByChild("email").equalTo(this.user.email).once('value').then(snapshot => {
      return snapshot.val()      
    })   
  }

  getPublicVocabLists(){
    return this.rootRef.child(this.vocabListPath).orderByChild("share_ALL").equalTo(1).once('value').then(snapshot => {
      return snapshot.val()      
    })   
  }

  getClassVocabLists(){
    return this.rootRef.child(this.vocabListPath).orderByChild("share_1A").equalTo(1).once('value').then(snapshot => {
      return snapshot.val()      
    })   
  }

  getFormVocabLists(){
    return this.rootRef.child(this.vocabListPath).orderByChild("share_F1").equalTo(1).once('value').then(snapshot => {
      return snapshot.val()      
    })   
  }

  changeShare(vocablist){

    return this.rootRef.child(this.vocabListPath+"/"+vocablist.id).set(vocablist)
    
  }

  getVocabListByID(id){
    console.log("ID:",id)
    return this.rootRef.child(this.vocabListPath+"/"+id).once('value').then(snapshot => {
      return snapshot.val()      
    })   
  }
  
  deleteMyVocabList(vocablistID){
    return this.rootRef.child(this.vocabListPath+"/"+vocablistID).remove()
  }

  addMyVocabList(data){

    console.log(data)
    data.user_display_name = this.user.displayName
    data.email = this.user.email
    return this.db.list(this.vocabListPath).push(data)
  }

  addVocabWord(vocablist,word){
    console.log(vocablist,word)
    length = 0
    try{
      length = vocablist.words.length
    }
    finally{
      return this.rootRef.child(this.vocabListPath+"/"+vocablist.id+"/words/"+length).set(word)
    }

    
  }

  deleteVocabWord(vocablist,wordlist){
    return this.rootRef.child(this.vocabListPath+"/"+vocablist.id+"/words/").set(wordlist)
  }

  

  serverLogin(token: string){
    console.log("Trying to login ... ")
    return this.http.post(baseURL + 'login.php', {'token': token} , httpOptions);
  }

  serverLogout(){
    return this.http.get(baseURL + 'logout.php' , httpOptions);
  }

  getBooks(){
    return this.http.get(baseURL + 'get_books.php' , httpOptions);
  }

  getSources(){
    return this.http.get(baseURL + 'static/get_source.php' , httpOptions);
  }

  getGenres(){
    return this.http.get(baseURL + 'static/get_genre.php' , httpOptions);
  }

  updateProgress(data){
    return this.http.post(baseURL + 'update_progress.php' , data,httpOptions);
  }

  inputBook(data){
    return this.http.post(baseURL + 'add_book.php' , data,httpOptions);
  }

  getVocabLists(){
    return this.http.get(baseURL + 'vocablist/get_list.php' , httpOptions);
  }

  getVocab(listid : number){
    return this.http.get(baseURL + 'vocablist/get_vocab.php?list='+listid , httpOptions);
  }

  addVocab(data){
    return this.http.post(baseURL + 'vocablist/add_vocab.php',data, httpOptions);
  }

  addList(data, isUndo = 0){
    if (!isUndo){
      return this.http.post(baseURL + 'vocablist/add_list.php',data, httpOptions);
    } else {
      return this.http.post(baseURL + 'vocablist/undo_delete_list.php',data, httpOptions);
    }
   
  }


  deleteList(data){
    return this.http.post(baseURL + 'vocablist/delete_list.php',data, httpOptions);
  }

  deleteVocab(data){
    return this.http.post(baseURL + 'vocablist/delete_vocab.php',data, httpOptions);
  }
  getLearnerDictionary(word: string){
    return this.http.get('https://www.dictionaryapi.com/api/v3/references/learners/json/'+word+'?key=5197cd98-5eb8-4bbd-857c-17223003da3d' );
  }

  getOxfordDictionary(word: string){
    return this.http.get('https://od-api.oxforddictionaries.com/api/v2/entries/en-gb/ace?strictMatch=false', OxfordhttpOptions );
  }

  getRhymeDictionary(word: string){
    return this.http.get('https://api.studydse.net/eeapi/dictionaries/rhyme.php?word='+word );
  }

  getJustTheWordDictionary(word: string){
    return this.http.get('https://api.studydse.net/eeapi/dictionaries/jtw.php?word='+word  );
  }

  getOneLookDictionary(word: string){
    return this.http.get( 'https://api.studydse.net/eeapi/dictionaries/onelook.php?word='+word  );
  }

  getVocabularycomDictionary(word: string){
    return this.http.get('https://vocabulary.now.sh/word/'+word  );
  }
}
